import { isFuture } from "date-fns";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getNewsUrl(slug) {
  return `/aktualnosci/${slug.current || slug}/`;
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

export function sortNavItemsByOrder(items, order) {
  return items.sort(function (a, b) {
    var x = a[order];
    var y = b[order];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function pushShortWordsLineBelow(content) {
  content.forEach((block) => {
    block.children &&
      block.children.forEach((element) => {
        let text = element.text.split(" ");
        text.forEach((word, index) => {
          const nextWord = text[index + 1];
          if (
            (word.length < 3 || word === "ul." || nextWord === "r." || nextWord === "zł") &&
            word !== "r." &&
            word !== "zł" &&
            index !== text.length - 1
          ) {
            text.splice(index, 1, word.concat("\u00A0"));
          } else {
            if (index !== text.length - 1) {
              text.splice(index, 1, word.concat(" "));
            }
          }
        });
        const textWithHardSpace = text.join("");
        element.text = textWithHardSpace;
      });
  });
}

export function pushShortWordsLineBelowInATitle(title) {
  let text = title.split(" ");
  text.forEach((word, index) => {
    const nextWord = text[index + 1];
    if (
      (word.length < 3 || word === "ul." || nextWord === "r." || nextWord === "zł") &&
      (word !== "r." || word !== "zł") &&
      index !== text.length - 1
    ) {
      text.splice(index, 1, word.concat("\u00A0"));
    } else {
      if (index !== text.length - 1) {
        text.splice(index, 1, word.concat(" "));
      }
    }
  });
  const textWithHardSpace = text.join("");
  return textWithHardSpace;
}
